$toolbar-height: 120px;
$input-height: 160px;
$blue: #1270B6;
$dark-blue: #005FAA;
$grey: #c1c1c1;
$dark-grey: #484848;
$hard-dark-grey: #333132;
$light-grey: #f5f5f5;
$light-medium-grey: #a7a9Ad;
$orange: #f89406;
$green: #388e3c;
$hover: #ECF1F7;
$white: #ffffff;

$brand-danger: #f44336;
$brand-success: #1270B6;

$container-border-radius: 7px;
$mat-form-field-border-radius: 18px;
