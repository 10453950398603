@import './variables';

:root {
    --color-background: #fff;
    --color-text: #000;
    --color-placeholder: #000;
    --color-text-inverted: #fff;
    --color-text-dark-blue: #1D2D35;
    --color-primary: #1E85CC;
    --color-primary-lighter: #6BC0FB;
    --color-question-background: #F4F4F4;
    --color-error: #EF5A40;
    --color-error-lighter: #EF8A40;
    --color-border: #F2F2F2;
    --color-toolbar-background: #F6F8FA;
    --color-boxshadow: #00000029;
    --color-text-gray: #84818A;
    --color-btn-dark-green: #309a00;
    --color-btn-green: #3cc300;
    --color-text-light-blue: #015FAA;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body {
    height: 100%;
    scroll-behavior: smooth;
    font-family: Roboto, sans-serif;
}

body {
    margin: 0;
    color: var(--color-text);
}

a {
    color: var(--color-primary)
}

.horizontal-ruler {
    border: none;
    background-color: var(--color-border);
    height: 2px;
    margin: 0 4px;
}

.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.flex-spacer {
    flex: 1 1 auto;
}

circle {
    stroke: var(--color-primary-lighter) !important;
}

.mat-button-disabled {
    color: var(--color-primary-lighter) !important;
}

.error {
    color: var(--color-error) !important;
}

@for $i from 0 through 60 {
    .icon-#{$i} {
      height: #{$i} + 'px';
      width: #{$i} + 'px';
      min-width: #{$i} + 'px';
      min-height: #{$i} + 'px';
      max-width: #{$i} + 'px';
      max-height: #{$i} + 'px';
      font-size: #{$i} + 'px';
      margin:0;
      padding:0;
    }
}
// scrollbar start

/* Works on Firefox */

* {
    scrollbar-width: thin;
    scrollbar-color: #E2E8ED #F8F8F8;
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: #F8F8F8;
    border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
    background-color: #E2E8ED;
    border-radius: 20px;
    border: 3px solid #F8F8F8;
}

// scrollbar stop


.dark-mode {
// scrollbar start

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #B5B3B5 #2B2B2B;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #2B2B2B;
    border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
    background-color: #B5B3B5;
    border-radius: 20px;
    border: 3px solid #B5B3B5;
}

// scrollbar stop
}

.contrast-mode {
    // scrollbar start

    /* Works on Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #3E3E3E #E0E0E0;
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 10px;
    }

    *::-webkit-scrollbar-track {
        background: #E0E0E0;
        border-radius: 20px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #3E3E3E;
        border-radius: 20px;
        border: 3px solid #3E3E3E;
    }

    // scrollbar stop
}

//mat-form start

/* Change the border color */

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: $dark-blue !important;
}


/* Change the border width */
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
    border-width: 1.5px !important;
}

/* Change the border focused color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: $dark-blue !important;
}

/* Change the border hover color */
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: $dark-blue !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
    border-left: none !important;
}

.mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: 18px !important;
}

.mdc-text-field--focused .mdc-floating-label,
.mdc-text-field--notched-outline .mdc-floating-label {
    color: $dark-grey !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
    border: 1.5px solid $dark-blue;
    border-radius: 18px;
    background-color: $white;
    width: 55%;
}
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
}
.mat-mdc-form-field .mdc-line-ripple {
    opacity: 0 !important;
    background-color: transparent !important;
}
.mat-mdc-form-field.mat-focused .mdc-line-ripple {
    background-color: transparent !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
}

.mat-mdc-form-field-focus-overlay {
    background-color: transparent;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-form-field-flex:hover {
    background-color: transparent !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: $dark-blue;
}

.mat-select-input {
    width: 60%;
}

//mat-form stop

//radio-button start

.mat-mdc-radio-checked .mdc-radio__background .mdc-radio__outer-circle {
    border-color: $dark-blue !important;
}

.mat-mdc-radio-checked .mdc-radio__background .mdc-radio__inner-circle {
    background-color: $dark-blue !important;
    border: $dark-blue !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__inner-circle {
    background-color: $dark-blue !important;
}
.mdc-radio__outer-circle {
    border-color: $dark-blue !important;
}

.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mat-mdc-checkbox-checked .mdc-checkbox__background::before {
    background-color: $dark-blue !important;
    border-color: $dark-blue !important;
}

.mat-mdc-checkbox .mdc-checkbox__ripple::before,
.mat-mdc-checkbox .mdc-checkbox__ripple::after {
    border-color: $dark-blue !important;
}
.mdc-checkbox__background {
    border: 1px solid $dark-blue !important;
}

// radio-button stop

// mat-card start
.mat-mdc-card.mdc-card {
    background-color: #ffffff;
    border-radius: $container-border-radius !important;
    box-shadow: none !important;
}

// mat-card stop

@mixin btn-hover {
    margin-bottom: 20px;
    margin-left: 15px;
    height: 25px;
    width: 28px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}
